<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-6">
        <card>
          <div class="card-header">
            <h4 style="text-align: center;">Gerenciar Notificações</h4>
          </div>
          <div class="card-body">
            <p>
              Aqui, você tem o controle total sobre as informações que recebe. Ao selecionar os itens correspondentes às
              categorias de notificações desejadas, você indica suas preferências.
            </p>
            <p>
              Queremos garantir que você receba apenas as informações mais relevantes e interessantes. Personalize suas
              configurações agora e desfrute de uma experiência personalizada e adaptada às suas necessidades. Seu
              conforto e informação são nossa prioridade!
            </p>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card>
          <div class="card-header">
            <h4 style="text-align: center;">Notificações Disponíveis</h4>
          </div>
          <div class="card-body">
            <ul style="list-style: none">
              <li v-for="notification in notifications" :key="notification.type">
                <div>
                  <p-checkbox
                    :checked="!ignoredNotifications.includes(notification.type)"
                    @input="() => handleCheckboxClick(notification.type)"
                    :disabled="notification.disabled"
                  >
                    {{ notification.text }}
                  </p-checkbox>
                </div>
              </li>
            </ul>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {callWs} from "@/ws.service";
import swal from "sweetalert2";

export default {
  name: 'manage-notifications',
  data() {
    return {
      notifications: [
        {type: 'PIX_IN_BANKING', text: 'Recebimento de Pix - Banking', disabled: false},
        {type: 'PIX_OUT_BANKING', text: 'Saída de Pix - Banking', disabled: false},
        {type: 'TICKET_PAYMENT_SUCCESS', text: 'Liquidação de Boletos', disabled: false},
        {type: 'TICKET_PAYMENT_FAIL', text: 'Erro na liquidação de boletos', disabled: false},
      ],
      ignoredNotifications: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      callWs('/notification/notification-preference',
        'GET',
        null,
        true,
        {
          username: localStorage.getItem('username')
        },
        null,
        this.successGetData,
        this.failGetData
      )
    },
    successGetData(response) {
      this.ignoredNotifications = response.data.ignoredNotifications
    },
    failGetData() {
      swal({
        title: 'Aviso!',
        text: 'Ocorreu um erro inesperado ao buscar suas preferências. Atualize a página e tente novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    handleCheckboxClick(notificationType) {
      this.notifications.forEach(notification => {
        if (notification.type === notificationType) {
          notification.disabled = true
        }
      })
      this.updatePreference(notificationType)
    },
    updatePreference(notificationType) {
      callWs('/notification/update-notification-preference',
        'POST',
        null,
        true,
        null,
        {
          username: localStorage.getItem('username'),
          notificationType: notificationType
        },
        this.successUpdatePreference,
        this.failUpdatePreference
      )
    },
    successUpdatePreference(response) {
      this.notifications.forEach(notification => {
        notification.disabled = false
      })
      this.ignoredNotifications = response.data.ignoredNotifications
    },
    failUpdatePreference() {
      swal({
        title: 'Aviso!',
        text: 'Ocorreu um erro inesperado ao atualizar suas preferências. Atualize a página e tente novamente!',
        buttonsStyling: true,
        timer: 5000,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    }
  }
}
</script>

<style scoped>

</style>
