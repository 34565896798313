import { render, staticRenderFns } from "./ManageNotifications.vue?vue&type=template&id=5d482528&scoped=true"
import script from "./ManageNotifications.vue?vue&type=script&lang=js"
export * from "./ManageNotifications.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d482528",
  null
  
)

export default component.exports