<template>
  <card>
    <p style="color: #001a3f">
      Dados do Boleto
    </p>
    <ul>
      <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Beneficiário: {{boleto.beneficiaryName}}<br></p>
      <p v-if="boleto.type == 'BOLETO'"><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> CNPJ/CPF Beneficiário: {{boleto.beneficiaryDocument}}<br></p>
      <p v-if="boleto.type == 'BOLETO'"><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Data de Vencimento: {{boleto.dueDate}}<br></p>
      <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Linha Digitável: {{boleto.digitableLine}}</p>
      <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Código de Barras: {{boleto.barCode}}</p>
      <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Valor do Boleto: R$ {{toMoney(boleto.value)}}<br></p>
      <p v-if="boleto.type == 'BOLETO'"><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Descontos: R$ {{toMoney(boleto.discounts)}}<br></p>
      <p v-if="boleto.type == 'BOLETO'"><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Juros: R$ {{toMoney(boleto.interest)}}<br></p>
      <p v-if="boleto.type == 'BOLETO'"><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i> Multa: R$ {{toMoney(boleto.assessment)}}<br></p>
      <p><i style="color: #001a3f" class="fas fa-arrow-circle-right"></i><b> Valor à Pagar: R$ {{toMoney(boleto.amountPaid)}}</b><br></p>
      <label class="control-label">Descrição</label>
      <fg-input type="text"
                v-model="boleto.description"
                maxlength="150"
                name="Descrição"
                key="description"
                :disabled=readOnly>
      </fg-input>
    </ul>
  </card>
</template>
<script>
  import {toMoney} from "src/util/core.utils"
  export default {
    name: 'boleto-details',
    props: {
      boleto: {},
      readOnly: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toMoney
    }
  }
</script>
